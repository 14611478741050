import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { trackPage } from '../../services/helpers'

const Uninstall = () => {
  useEffect(() => {
    trackPage("Uninstall page")
    navigate("https://forms.gle/pqiiT6VFhtgNYGVf8")
  }, [])

  return (
    <div>

    </div>
  )
}

export default Uninstall